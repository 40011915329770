<template>
  <div>
    <div>
      <div
        class="operationControl personalSpecialOrder"
        style="
          border-bottom: 1px solid transparent;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        "
      >
        <div class="searchbox" style="margin-bottom: 10px">
          <div title="订单编号" class="searchboxItem ci-full">
            <span class="itemLabel">订单编号:</span>
            <el-input
              v-model="selectDate.orderSn"
              type="text"
              size="small"
              placeholder="请输入订单编号"
              clearable
            />
          </div>
          <div title="专题名称" class="searchboxItem ci-full">
            <span class="itemLabel">专题名称:</span>
            <el-input
              v-model="selectDate.productName"
              type="text"
              clearable
              size="small"
              placeholder="请输入专题名称"
            />
          </div>
          <div title="买家姓名" class="searchboxItem ci-full">
            <span class="itemLabel">买家姓名:</span>
            <el-input
              v-model="selectDate.userName"
              type="text"
              size="small"
              placeholder="请输入买家姓名"
              clearable
            />
          </div>
          
        </div>
        <div class="searchbox" style="margin-bottom: 10px">
          <div title="订单状态" class="searchboxItem ci-full">
            <span class="itemLabel">订单状态:</span>
            <el-select
              size="small"
              v-model="selectDate.orderStatus"
              clearable
              placeholder="请选择订单状态"
              style="width: 100%"
            >
              <el-option
                v-for="item in orderStatusList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div title="支付方式" class="searchboxItem ci-full">
            <span class="itemLabel">支付方式:</span>
            <el-select
              size="small"
              v-model="selectDate.orderMethod"
              clearable
              placeholder="请选择支付方式"
            >
              <el-option
                v-for="item in orderMethodList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div title="下单方式" class="searchboxItem ci-full">
            <span class="itemLabel">下单方式:</span>
            <el-select
              v-model="selectDate.orderPhone"
              clearable
              placeholder="请选择下单方式"
              size="small"
            >
              <el-option
                v-for="item in orderPhoneList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="searchbox">
          <div title="买家手机号" class="searchboxItem ci-full">
            <span class="itemLabel">买家手机号:</span>
            <el-input
              v-model="selectDate.mobile"
              type="text"
              size="small"
              placeholder="请输入买家手机号"
              clearable
            />
          </div>
          <div title="下单时间" class="searchboxItem ci-full">
            <span class="itemLabel">下单时间:</span>
            <el-date-picker
              v-model="selectDate.date"
              type="daterange"
              size="small"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
            />
          </div>
          <el-button class="bgc-bv" round @click="getData()">查询</el-button>
          <el-button class="bgc-bv" round @click="Export()">导出</el-button>
        </div>
      </div>
      <h4 style="padding: 8px 10px">订单总金额：{{ moneySum }}元</h4>
      <div class="framePage-scroll">
        <div class="ovy-a">
          <el-table
            ref="multipleTable"
            :data="tableData"
            :height="tableHeight"
            size="small"
            tooltip-effect="dark"
            style="width: 100%"
            stripe
            :header-cell-style="tableHeader"
          >
            <el-table-column
              label="序号"
              align="center"
              type="index"
              :index="indexMethod"
            />
            <el-table-column
              label="订单编号"
              align="left"
              show-overflow-tooltip
              prop="orderSn"
              width="200px"
            />
            <el-table-column
              label="专栏名称"
              align="left"
              show-overflow-tooltip
              prop="productName"
              width="200px"
            />
            <el-table-column
              label="价格（元）"
              align="left"
              show-overflow-tooltip
              prop="productPrice"
            />
            <el-table-column
              label="实付金额（元）"
              align="left"
              show-overflow-tooltip
              prop="paymentMoney"
            />
            <el-table-column
              label="买家姓名"
              align="left"
              show-overflow-tooltip
              prop="userName"
            />
            <el-table-column
              label="买家手机号"
              align="left"
              show-overflow-tooltip
              prop="mobile"
            />
            <el-table-column
              label="下单方式"
              align="left"
              show-overflow-tooltip
              prop="orderPhone"
            />
            <el-table-column
              label="支付方式"
              align="left"
              show-overflow-tooltip
              prop="paymentMethod"
            />
            <el-table-column
              label="下单时间"
              align="left"
              show-overflow-tooltip
              prop="createTime"
            />
            <el-table-column
              label="订单状态"
              align="left"
              show-overflow-tooltip
              prop="orderStatus"
            />
            <el-table-column label="操作" align="center" width="100">
              <div slot-scope="scope">
                <el-button
                  type="text"
                  size="mini"
                  style="padding: 0 5px"
                  @click="getDetails(scope.row.orderId)"
                  >详情</el-button
                >
              </div>
            </el-table-column>
            <Empty slot="empty" />
          </el-table>
        </div>
      </div>
      <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
    </div>
    <el-dialog
      ref="detailsform"
      :model="detailsform"
      prop="detailsform"
      :visible.sync="dialogBus"
      title="订单详情"
      width="40%"
      top="2%"
      center
      @close="closeCode"
    >
      <div class="orderDetail">
        <div
          :class="
            ruleForm.orderStatus != '已完成' ? 'order_err' : 'order_success'
          "
        >
          <div style="display: flex; align-items: center">
            <i
              :class="
                ruleForm.orderStatus != '已完成'
                  ? 'el-icon-error'
                  : 'el-icon-success'
              "
              style="font-size: 16px"
            ></i>
            <span class="orderStatus">{{ ruleForm.orderStatus }}</span>
          </div>
        </div>
        <div class="order_form">
          <h4>订单信息</h4>
          <el-divider class="divider"></el-divider>
          <div class="df flexwp orderBox">
            <div class="df">
              <span>订单编号：</span>
              <span>{{ ruleForm.orderSn }}</span>
            </div>
            <div class="df">
              <span>下单方式：</span>
              <span>{{
                ruleForm.orderPhone == "IOS"
                  ? "苹果手机"
                  : ruleForm.orderPhone == "WECHAT_MINI"
                  ? "小程序"
                  : "安卓手机"
              }}</span>
            </div>
            <div class="df">
              <span>下单时间：</span>
              <span>{{ ruleForm.createTime }}</span>
            </div>
            <div class="df">
              <span>支付方式：</span>
              <span v-if="ruleForm.paymentMethod == 1">苹果内部支付</span>
              <span v-else-if="ruleForm.paymentMethod == 2"
                >银联支付宝支付</span
              >
              <span v-else-if="ruleForm.paymentMethod == 3">银联微信支付</span>
              <span v-else-if="ruleForm.paymentMethod == 4">微信支付</span>
              <span v-else-if="ruleForm.paymentMethod == 9">积分支付</span>
              <span v-else>--</span>
            </div>
            <div class="df">
              <span>支付时间：</span>
              <span>{{ ruleForm.payTime || "--" }}</span>
            </div>
            <div class="df">
              <span>支付单号：</span>
              <span>{{ ruleForm.thirdPartPaymentNumber || "--" }}</span>
            </div>
            <div class="df">
              <span
                >{{
                  ruleForm.orderStatus != '未完成' ? "实付金额" : "待付金额"
                }}：</span
              >
              <span>{{ ruleForm.paymentMoney || "--" }}</span>
            </div>
          </div>
          <h4>买家信息</h4>
          <el-divider></el-divider>
          <div class="df flexwp orderBox">
            <div class="df">
              <span>买家姓名：</span>
              <span>{{ ruleForm.userName }}</span>
            </div>
            <div class="df">
              <span>手机号：</span>
              <span>{{ ruleForm.mobile }}</span>
            </div>
          </div>
          <h4>商品信息</h4>
          <el-divider></el-divider>
          <el-table
            ref="multipleTable"
            :data="ruleForm.miniCourses"
            size="small"
            tooltip-effect="dark"
            style="width: 100%"
            stripe
            :header-cell-style="tableHeader"
          >
            <el-table-column
              label="课程名称"
              align="left"
              show-overflow-tooltip
              prop="courseName"
            />
            <!-- <el-table-column
              label="价格"
              align="left"
              show-overflow-tooltip
              prop="miniPrice"
            /> -->
          </el-table>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
import { mapGetters } from "vuex";
export default {
  name: "marketUserList",
  components: {
    Empty,
    PageNum,
  },
  props: {
    tabs: {
      type: String,
      default: "",
    },
  },
  mixins: [List],
  data() {
    return {
      authentication: "",
      // coursesCourceList: [], //课程来源
      authenticationList: [], //订单状态list
      centerDialogVisible: false, //
      options: [],
      //   ownform: {
      //     yearnoidListoptions: [], //年份
      //     yearnoidList: "",
      //     yearListoptions: [],
      //     projectName: "", //班级名称
      //     projectCode: "", //班级编码
      //     shanxiUserStart: "",
      //     shanxiUserEnd: "",
      //   },
      detailsform: {},
      dialogBus: false,
      appealInfor: {},
      selectDate: {
        orderSn: "",
        productSourceName: "",
        productSourceId: "",
        userName: "",
        mobile: "",
        orderStatus: "",
        orderMethod: "",
        orderPhone: "",
        sourceId: "",
        date: "",
      },
      orderStatusList: [
        {
          value: "1",
          label: "未完成",
        },
        {
          value: "2",
          label: "已完成",
        },
        {
          value: "3",
          label: "退款中",
        },
        {
          value: "4",
          label: "退款失败",
        },
        {
          value: "5",
          label: "已退款",
        },
      ],
      orderMethodList: [],
      orderPhoneList: [
        {
          value: "IOS",
          label: "苹果手机",
        },
        {
          value: "AND",
          label: "安卓手机",
        },
        {
          value: "WECHAT_MINI",
          label: "小程序",
        },
      ],
      ruleForm: {},
      moneySum: 0,
    };
  },
  watch: {
    // 监听 - userId
    tabs: {
      handler: function (val) {
        if (val == "second") {
          this.getData(-1);
        }
      },
      immediate: true,
    },
  },
  computed: {
    getuser() {
      let user = "";
      if (this.marketLeaderArr.length) {
        user = this.marketLeaderArr.find(
          (el) => el.adminId == this.dialogRightMarketUser
        ).fullname;
      }
      return user;
    },
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  mounted() {
    this.getOrderMethodList();
    this.getAuthenticationList();
    // this.getcoursesCourceList();
    this.getTableHeight();
  },
  created() {},
  methods: {
    getOrderMethodList() {
      const OrderMethodList = this.$setDictionary("ORDER_METHOD", "list") || [];
      let list = [];
      for (const key in OrderMethodList) {
        if (OrderMethodList[key] != "积分支付") {
          list.push({
            value: key,
            label: OrderMethodList[key],
          });
        }
      }
      this.orderMethodList = list;
    },
    //课程来源
    getcoursesCourceList() {
      this.$post("/biz/ec/course/owner/list", {}, 3000, true, 2)
        .then((ret) => {
          this.coursesCourceList = ret.data || [];
        })
        .catch((err) => {
          return;
        });
    },
    getDetails(orderId) {
      this.dialogBus = true;
      this.$post(
        "/order/main/order-main/getColumnInfo",
        { orderId },
        3000,
        false,
        2
      )
        .then((ret) => {
          this.ruleForm = {
            ...ret.data,
          };
        })
        .catch((err) => {
          return;
        });
    },
    //订单状态
    getAuthenticationList() {
      const studylist = this.$setDictionary("AUTHENTICATION", "list");
      const list = [];
      for (const key in studylist) {
        list.push({
          value: key,
          label: studylist[key],
        });
      }
      this.authenticationList = list;
    },
    // 数组去重
    doRepeat(arr) {
      if (!Array.isArray(arr)) {
        return;
      }
      let checkArr = [];
      let newArr = [];
      for (let i = 0; i < arr.length; i++) {
        if (!checkArr.includes(arr[i].compId)) {
          checkArr.push(arr[i].compId);
          newArr.push(arr[i]);
        }
      }
      return newArr;
    },
    // 列表
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        productType: 8,
      };
      if (this.selectDate.date && this.selectDate.date.length) {
        params.createTime = this.selectDate.date[0];
        params.endTime = this.selectDate.date[1];
      }
      if (this.selectDate.orderSn) {
        params.orderSn = this.selectDate.orderSn;
      }
      if (this.selectDate.productName) {
        params.productName = this.selectDate.productName;
      }
      if (this.selectDate.userName) {
        params.userName = this.selectDate.userName;
      }
      if (this.selectDate.mobile) {
        params.mobile = this.selectDate.mobile;
      }
      if (this.selectDate.orderStatus) {
        params.orderStatus = this.selectDate.orderStatus;
      }
      if (this.selectDate.orderMethod) {
        params.paymentMethod = this.selectDate.orderMethod;
      }
      if (this.selectDate.orderPhone) {
        params.orderPhone = this.selectDate.orderPhone;
      }
      this.doFetch(
        {
          url: "/order/main/order-main/newPageList",
          params,
          pageNum,
        },
        false,
        2
      );
      this.getSum();
    },
    // 总收益
    getSum() {
      const params = {
        productType: 8,
      };
      if (this.selectDate.date && this.selectDate.date.length) {
        params.createTime = this.selectDate.date[0];
        params.endTime = this.selectDate.date[1];
      }
      if (this.selectDate.orderSn) {
        params.orderSn = this.selectDate.orderSn;
      }
      if (this.selectDate.productName) {
        params.productName = this.selectDate.productName;
      }
      if (this.selectDate.userName) {
        params.userName = this.selectDate.userName;
      }
      if (this.selectDate.mobile) {
        params.mobile = this.selectDate.mobile;
      }
      if (this.selectDate.orderStatus) {
        params.orderStatus = this.selectDate.orderStatus;
      }
      if (this.selectDate.orderMethod) {
        params.paymentMethod = this.selectDate.orderMethod;
      }
      if (this.selectDate.orderPhone) {
        params.orderPhone = this.selectDate.orderPhone;
      }
      this.$post(
        "/order/main/order-main/getNewSum",
        params,
        3000,
        true,
        2
      ).then((res) => {
        // ;
        if (res.status == "0") {
          this.moneySum = res.data;
        }
      });
    },
    // 导出
    Export() {
      const params = {
        productType: 8,
      };
      if (this.selectDate.date && this.selectDate.date.length) {
        params.createTime = this.selectDate.date[0];
        params.endTime = this.selectDate.date[1];
      }
      if (this.selectDate.orderSn) {
        params.orderSn = this.selectDate.orderSn;
      }
      if (this.selectDate.productName) {
        params.productName = this.selectDate.productName;
      }
      if (this.selectDate.userName) {
        params.userName = this.selectDate.userName;
      }
      if (this.selectDate.mobile) {
        params.mobile = this.selectDate.mobile;
      }
      if (this.selectDate.orderStatus) {
        params.orderStatus = this.selectDate.orderStatus;
      }
      if (this.selectDate.orderMethod) {
        params.paymentMethod = this.selectDate.orderMethod;
      }
      if (this.selectDate.orderPhone) {
        params.orderPhone = this.selectDate.orderPhone;
      }
      this.$post(
        "/order/main/order-main/listExportSubscribeSpecialAsyn",
        params,
        3000,
        true,
        2
      ).then((res) => {
        if (res.status == "0") {
          let list = res.data;
            for (let item of list) {
              console.log(item);
              if (!this.downloadItems.includes(item.taskId)) {
                this.$store.dispatch("pushDownloadItems", item.taskId);
              } else {
                this.$message.warning(
                  "[" + item.fileName + "]已经申请下载,请耐心等待"
                );
              }
            }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight -
        (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 3.25 + 6) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scoped>
.personalSpecialOrder {
  .itemLabel {
    min-width: 6rem;
  }
}
.orderDetail {
  .orderStatus {
    font-weight: 800;
    font-size: 16px;
    margin-left: 10px;
  }
  .order_err {
    padding: 10px;
    background-color: #7f7f7f;
    // border: 1px solid rgb(255, 204, 0);
    color: #fff;
  }
  .order_success {
    padding: 10px;
    background-color: #70b602;
    // border: 1px solid rgb(255, 204, 0);
    color: #fff;
  }
  .order_form {
    margin-top: 15px;
    div.df {
      padding: 8px 0;
      span:first-child {
        width: 6rem;
        text-align: right;
        margin-right: 8px;
      }
    }
  }
}
.divider {
  margin: 12px 0;
}
.orderBox {
  div {
    width: 50%;
    align-items: center;
  }
}
</style>
