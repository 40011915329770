<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">精品课程</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">订单列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <el-tabs v-model="activeName" :before-leave="beforeTabLeave" @tab-click="tabChange">
          <el-tab-pane label="精品课订单" name="first" >
            <qualityCourseOrder ref="first"  :tabs="activeName"/>
          </el-tab-pane>
          <el-tab-pane label="个人专栏订单" name="second">
            <personalSpecialOrder ref="second"  :tabs="activeName"/>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import List from "@/mixins/List";
import qualityCourseOrder from "@/views/CourseManagement/qualityCourseOrder.vue"; //精品课订单
import personalSpecialOrder from "@/views/CourseManagement/personalSpecialOrder.vue"; //个人专题订单
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "marketUserList",
  components: {
    qualityCourseOrder,
    personalSpecialOrder
  },
  mixins: [List],
  data() {
    return {
     activeName: "first",
    };
  },
  computed: {
 
  },
  mounted() {
  },
  created() {},
  methods: {
    // 点击tabs选项卡
    tabChange(tab, event) {
      this.activeName = tab.name;
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less">
.relation_jg_dialog {
  .el-dialog--center .el-dialog__body {
    padding: 25px 20px 30px;
  }
  .el-table--border {
    border-bottom: 1px solid #ebeef5;
    th {
      border-right: 1px solid #ebeef5;
    }
  }
  .table_box {
    width: 42%;
    .table_box_top {
      margin-bottom: 10px;
    }
    .list_top {
      padding: 15px;
      text-align: center;
      border: 1px solid #ebeef5;
      border-bottom: 0;
    }
    .list_search {
      border: 1px solid #ebeef5;
      border-top: 0;
      border-bottom: 0;
      padding: 0 10px 15px;
    }
  }
  // 左右按钮操作
  .btn_arrow {
    padding: 1.25rem;
    > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .relation_page {
    .el-pagination__sizes {
      .el-select {
        top: -3px;
      }
    }
  }
}
.form-item-nomargin {
  margin-bottom: 5px;
}
.form-item-color .el-form-item__label {
  color: #ff6e3b;
}

.orderDetail {
  .order_stu {
    padding: 10px;
    background-color: rgb(255, 255, 204);
    border: 1px solid rgb(255, 204, 0);
    color: rgb(255, 51, 0);
  }
  .order_form {
    div.df {
      padding: 8px 0;
      span:first-child {
        width: 6rem;
        text-align: right;
        margin-right: 8px;
      }
    }
  }
}
</style>
